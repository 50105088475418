import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { shySerializer } from '../utils'
import { BaseStyles } from '../theme'
import Image from 'gatsby-image'

import {
  AspectRatio,
  Box,
  Footer,
  FooterClaim,
  Heading,
  Layout,
  SEO,
  Text,
} from '../components'

const SimplePage = ({ data, path }) => {
  const prismicContent = data.prismic.allSimple_pages.edges[0]
  if (!prismicContent) return null
  const document = prismicContent.node
  const activeDocMeta = document._meta
  const activeLang = activeDocMeta.lang.split('-')[0]
  const pageTitle = RichText.asText(document.page_title)
  const backUrl = `/${activeLang}`

  const metaInformations = data.prismic.allMeta_informations.edges
    .slice(0, 1)
    .pop()
  const {
    instagram,
    facebook,
    youtube,
    vimeo,
    linkedin,
    footer_claim,
    contact_link_label,
    imprint_link_label,
    imprint_link,
    privacy_link_label,
    privacy_link,
    language_switch_label,
  } = metaInformations.node

  const slices =
    document.body &&
    document.body.map((slice, index) => {
      const key = `${slice.type}-${index}`
      if (slice.type === 'text') {
        return (
          <BaseStyles key={key}>
            <Text
              variant="textBodySplendid"
              sx={{ maxWidth: '54em', mx: 'auto', mt: 4 }}
            >
              {RichText.render(slice.primary.text)}
            </Text>
          </BaseStyles>
        )
      } else if (slice.type === 'text_large') {
        return (
          <BaseStyles key={key}>
            <Text
              variant="textBodyLarge"
              sx={{ maxWidth: '54em', mx: 'auto', mt: 4 }}
            >
              {RichText.render(slice.primary.text)}
            </Text>
          </BaseStyles>
        )
      }

      return console.warn(
        `The slice type '${slice.type}' is not supported by SimplePage.`
      )
    })

  const headlineLines = RichText.asText(document.headline).split('\n')
  const headlineColor = document.headline_color

  return (
    <Layout activeDocMeta={activeDocMeta}>
      <SEO title={pageTitle} lang={activeLang} />
      <Box
        sx={{
          position: 'relative',
          mx: 'auto',
          px: '10%',
          maxWidth: '1000px',
          textAlign: 'center',
          boxSizing: 'content-box',
        }}
      >
        <Box
          as="a"
          href={backUrl}
          sx={{
            display: 'block',
            width: '54px',
            mx: 'auto',
            mt: [1, null, 4, 6],
            mb: [1, null, 3, 5],
          }}
        >
          <AspectRatio ratio={54 / 80}>
            <Image fluid={data.closeIcon.childImageSharp.fluid} />
          </AspectRatio>
        </Box>
        <Heading as="h1" variant="heading10a" sx={{ textAlign: 'center' }}>
          <Heading as="span" variant="heading6line" />
          <Box
            as="span"
            sx={{
              color: headlineColor,
            }}
            dangerouslySetInnerHTML={{
              __html: shySerializer(headlineLines[0]),
            }}
          ></Box>
          {headlineLines.length >= 2 && (
            <Heading as="span" variant="heading10b">
              {headlineLines[1]}
            </Heading>
          )}
        </Heading>
        <main>{slices}</main>
      </Box>
      <FooterClaim text={RichText.asText(footer_claim)} />
      <Footer
        activeDocMeta={activeDocMeta}
        instagramUrl={instagram && instagram.url}
        facebookUrl={facebook && facebook.url}
        youtubeUrl={youtube && youtube.url}
        vimeoUrl={vimeo && vimeo.url}
        linkedinUrl={linkedin && linkedin.url}
        contactLabel={contact_link_label}
        imprintLabel={imprint_link_label}
        imprintLink={imprint_link}
        privacyLabel={privacy_link_label}
        privacyLink={privacy_link}
        languageLabel={language_switch_label}
      ></Footer>
    </Layout>
  )
}

export const query = graphql`
  query PageQuery($lang: String, $uid: String) {
    closeIcon: file(relativePath: { eq: "close.png" }) {
      childImageSharp {
        fluid(maxWidth: 54) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    prismic {
      allMeta_informations(lang: $lang) {
        edges {
          node {
            contact_link_label
            imprint_link_label
            imprint_link {
              _linkType
              ... on PRISMIC_Simple_page {
                _meta {
                  uid
                  lang
                }
              }
            }
            privacy_link_label
            privacy_link {
              _linkType
              ... on PRISMIC_Simple_page {
                _meta {
                  uid
                  lang
                }
              }
            }
            language_switch_label
            email
            footer_claim
            instagram {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            facebook {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            youtube {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            vimeo {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            linkedin {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
      allSimple_pages(lang: $lang, uid: $uid) {
        edges {
          node {
            _meta {
              lang
              tags
              type
              uid
              alternateLanguages {
                lang
                type
                uid
              }
            }
            page_title
            headline
            headline_color
            body {
              __typename
              ... on PRISMIC_Simple_pageBodyText_large {
                type
                primary {
                  text
                }
              }
              ... on PRISMIC_Simple_pageBodyText {
                type
                primary {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`

export default SimplePage
